.usrPlan-flexBox .nunito-sans {
	font-family: 'Nunito-sans', sans-serif;
	/* color: var(--font-clr1); */
}

.usrPlan-flexBox .txt-decoration {
	text-decoration: underline;
}

.usrPlan-container .usrPlan-flexBox {
	border-bottom: 1px solid var(--border-clr1);
}

.usrPlan-container .usrPlan-flexBox:last-child {
	border-bottom: none;
}

.usrPlan-flexBox .primary-clr {
	color: var(--font-clr1);
}

.alt-txt-clr {
	color: var(--alt-font-clr1);
}
